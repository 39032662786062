<template>
	<div>
		<edit-consultant :profile="systemUser"  @saved="createUser()" />
	</div>
</template>

<script>
import api from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels";


import moment from 'moment'
import EditSystemUser from '@/components/modals/forms/EditSystemUser.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: { "edit-consultant":EditSystemUser },
  name: "EditSystemUser",
  
  data(){
	  return {
		  systemUser:{
			name:null,
			surname:null,
			email:null,
			phone:null,
			title:'Sistem Kullanıcısı',
			bio:null,
			birthday:null,
			career:null,
			city:null,
			countryIso:'TR',
			education:null,
			gender:null,
			status:1,
			userTypeId:2,
			
		}
    }
  },
 
  methods:{
    moment(date){
      return moment(date);
    },
	
	createUser(){
		
		api.post('/User/NewUser',this.systemUser).then((res)=>{
            console.log(res);
        }).catch((err)=>{
            console.log(err.response);
        })
	}
    
  },
  mounted(){
	  
	  
      setCurrentPageBreadcrumbs("Yeni Kullanıcı Ekleme", ["Sistem Kullanıcıları"]);    
  }
  
  }
</script>
